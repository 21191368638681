import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/7.jpg)`,
                        }}
                    >
                        <h1>TURBO lavadora</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Zion Tech desarrolló un nuevo turbolavador que se combina con funciones de fricción y
                                deshidratación. El pegamento se eliminará mediante una fuerte fricción entre las escamas
                                y las paletas, luego la tasa de humedad de las escamas de PET se reducirá por debajo del
                                5% mediante la fuerza centrífuga. Los dispositivos de rociado de agua pueden evitar la
                                obstrucción de la pantalla y enjuague las escamas de PET con agua reciclada. Las paletas
                                y las pantallas son desmontables, lo que facilita el mantenimiento y las reparaciones
                                diarias. Las paletas están adoptando acero de aleación que tiene resistencia a la
                                abrasión, la vida útil de las paletas puede alcanzar las 10,000 toneladas de producción
                                de escamas de PET
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Hay una gran cantidad de pegamento adherido a las escamas de PET, aunque las etiquetas
                                de PP y las etiquetas de papel se separan de las botellas de PET mediante el lavado.
                                Este pegamento residual y las pequeñas impurezas afectan las aplicaciones de rPET en la
                                producción posterior. La lavadora turbo elimina el pegamento y las pequeñas impurezas de
                                las escamas mediante la fuerza de fricción para Produce escamas de rPET de limpieza.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-6000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
